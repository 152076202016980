import { acceptCookies } from "@/utils/acceptCookies";
import * as Dialog from "@radix-ui/react-dialog";
import Link from "next/link";
import { ReactNode, useState } from "react";

interface CustomProps {
  children: ReactNode;
  onAccept: () => void;
}

export function Custom({ children, onAccept }: CustomProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isAllMarked, setIsAllMarked] = useState(false);

  function handleSave() {
    acceptCookies({ all: isAllMarked });
    setIsOpen(false);
    onAccept();
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-[9999] bg-black/60" />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-[9999] w-[95%] max-w-3xl -translate-x-1/2 -translate-y-1/2 rounded-md font-montserrat">
          <div className="relative bg-contact-bg py-6">
            <strong className="block px-12 text-center font-uiDisplay font-semibold uppercase">
              Cookies de política de privacidade
            </strong>
            <Dialog.Trigger asChild>
              <button
                aria-label="Fechar formulário"
                className="absolute right-6 top-1/2 -translate-y-1/2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#000000"
                  viewBox="0 0 256 256"
                >
                  <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
                </svg>
              </button>
            </Dialog.Trigger>
          </div>
          <div className="flex max-h-[75vh]  flex-col gap-8 overflow-auto bg-residencial-white px-4 py-10 md:px-6">
            <div>
              <div className="flex items-center gap-2">
                <input
                  id="necessario"
                  type="checkbox"
                  checked={true}
                  className="h-4 w-4"
                />
                <label htmlFor="necessario" className="text-lg">
                  Estritamente Necessário
                </label>
              </div>
              <p className="mt-4">
                Esses cookies são necessários para o funcionamento do site e não
                podem ser desativados em nossos sistemas. Normalmente, eles são
                definidos apenas em resposta a ações feitas por visitantes que
                equivalem a uma solicitação de serviços, como definir
                preferências de privacidade, fazer login ou preencher
                formulários. Você pode configurar seu navegador para bloquear ou
                alertá-lo sobre esses cookies, mas isso pode fazer com que
                partes do site não funcionem corretamente. Esses cookies não
                armazenam nenhuma informação de identificação pessoal.
              </p>
            </div>
            <div>
              <div className="flex items-center gap-2">
                <input
                  id="mkt"
                  type="checkbox"
                  checked={isAllMarked}
                  onChange={(e) => setIsAllMarked(e.target.checked)}
                  className="h-4 w-4"
                />
                <label htmlFor="mkt" className="text-lg">
                  Cookie MKT
                </label>
              </div>
              <p className="mt-4">
                Os cookies de marketing fornecem informações sobre a interação
                do usuário com o conteúdo do nosso site, ajudando-nos a entender
                melhor a eficácia do nosso e-mail e do conteúdo do site.
              </p>
              <div className="mt-6 flex flex-col gap-4 font-medium text-residencial-orange-500">
                <Link
                  href="https://static.googleusercontent.com/media/www.google.com/pt-BR//intl/pt-BR/policies/privacy/google_privacy_policy_pt-BR.pdf"
                  target="_blank"
                >
                  + Google AdSense
                </Link>
                <Link
                  href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0"
                  target="_blank"
                >
                  + Facebook
                </Link>
                <Link
                  href="https://www.facebook.com/help/instagram/519522125107875"
                  target="_blank"
                >
                  + Instagram
                </Link>
              </div>
            </div>
            <div className="flex justify-end gap-4">
              <Dialog.Trigger asChild>
                <button className="mt-4 h-10 rounded-sm border-2 border-residencial-teal px-4 font-semibold text-residencial-teal transition-all enabled:hover:brightness-95">
                  Fechar
                </button>
              </Dialog.Trigger>
              <button
                onClick={handleSave}
                className="mt-4 h-10 rounded-sm bg-residencial-teal px-4 font-semibold text-residencial-white transition-all enabled:hover:brightness-95"
              >
                Salvar
              </button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
