"use client";

import { useEffect, useState } from "react";
import PrivacityModal from "../PrivacityModal";
import { acceptCookies } from "@/utils/acceptCookies";
import { Custom } from "./Custom";

export function Cookie() {
  const [cookie, setCookie] = useState(true);

  useEffect(() => {
    setCookie(Boolean(localStorage.getItem("Cookies")));
  }, []);

  if (cookie) {
    return null;
  }

  function handleAccept() {
    setCookie(true);
    acceptCookies({ all: true });
  }

  return (
    <div className="fixed bottom-0 left-0 z-[9999] flex w-full flex-col items-end bg-contact-bg p-4 text-sm lg:max-w-[50vw]">
      <p>
        Para melhorar a sua experiência de navegação, utilizamos de cookies,
        entre outras tecnologias. De acordo com a nossa Política de Privacidade,
        ao continuar navegando, você aceita estas condições. Acesse nossa
        <PrivacityModal>
          <button className="pl-1 underline underline-offset-2">
            Política de Privacidade.
          </button>
        </PrivacityModal>
      </p>
      <div className="flex gap-4">
        <Custom onAccept={() => setCookie(true)}>
          <button className="mt-4 h-10 rounded-sm border-2 border-residencial-teal px-4 font-semibold uppercase text-residencial-teal transition-all enabled:hover:brightness-95">
            Definir Cookies
          </button>
        </Custom>
        <button
          className="mt-4 h-10 rounded-sm bg-residencial-teal px-4 font-semibold uppercase text-residencial-white transition-all enabled:hover:brightness-95"
          onClick={handleAccept}
        >
          Aceitar Todos
        </button>
      </div>
    </div>
  );
}
